import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout title="How It Works">
    <h2>How It Works</h2>
    <div className="clear-block">
      <div id="node-7" className="node">


        <div className="content clear-block">
          <p><img src="/images/015-calle-300x225.jpg" className="float-left" width="300" height="225" alt=""/>
            Our most important goal as music educators is build a strong foundation of accurate
            note reading and timing. The Animal Note Method for beginning piano and guitar music has been carefully
            designed to allow parents and teachers to do just that; in an atmosphere of fun while teaching these very
            important skills. This method, based on associative learning, also introduces some basic elements of theory
            and provides a solid method for moving from the Animal Notes to standard music notation. From the beginning
            books of Note Reading and Timing, the series moves to books containing songs most children are familiar with
            and love to play; two Fun Books and a book of Christmas songs. These books put into practice the lessons
            learned in the in the Note Reading and Timing books and help strengthen their foundation in music education.
          </p>
          <p> The set of Animal Note/standard note Flash Cards is a tool that strengthens the student’s knowledge of the
            word clues associated with each Animal Note and its standard note. This helps the student remember the
            location of each note on the grand staff. The two levels of Moving on Books provide a smooth and supportive
            way to transfer the knowledge from the Animal Notes to standard music notation, thus creating a comfortable
            switch from the fun animals to the standard notes. The Theory book gives an explanation of some of the basic
            elements of theory in a way most children can understand and a very helpful tool for the parents that are
            teaching their children. The material is organized in a step by step, block by block building method so new
            knowledge is added to the structure of what has already been learned. </p>
          <h3>Testimonial</h3>
          <p><i>"Thanks you so much for your kind offer, and words.<br/>
            My just turned 3 year old grand daughter is interested in the piano, and I bought this for her...She lives
            in Portland ( I live in California) but she has a neighbor who is majoring in music , and babysits her, and
            offered to teach her, if mommy ( read grandmom) could find a program that might work...I was so excited when
            I found this one ( isn't the world wide web wonderful...sometimes??)<br/>
            Again, many thanks for your kindness,"</i><br/>
            - Kathy</p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>

  </Layout>
)
